.image-gallery-ads{
    background: rgba(34, 43, 69, 0.9);
    width: 40%;
    height: 100%;
    bottom: 0px;
    color: #fff;
    left: 0;
    line-height: 1;
    padding: 10px 20px;
    position: absolute;
    white-space: normal;
}

.image-gallery-thumbnail-image img{
    height: 60px;
    object-fit: cover;
}